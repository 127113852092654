<template>
  <v-carousel cycle height="400" hide-delimiters>
    <v-carousel-item v-for="n in 3" :key="n" src="@/assets/images/carousel.png">
      <v-card  tile color="transparent" dark flat height="100%">
        <v-row align="center" class="fill-height" justify="center" no-gutters>
          <v-col class="pa-12" md="6" sm="8">
            <v-overlay absolute>
              <v-card-title class="green--text text--accent-3">
                {{ $vuetify.lang.t('$vuetify.pages.home.carouselTitle') }}
              </v-card-title>
              <v-card-text class="text-start">
                {{ $vuetify.lang.t('$vuetify.pages.home.carouselText') }}
              </v-card-text>
            </v-overlay>
          </v-col>
        </v-row>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'KurccCarousel'
}
</script>
